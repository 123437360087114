import { React, Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { checkAgent } from "./../../../config/api_calls";
import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";
import {
  ValidateEmail,
  axiosConfig,
  normalize,
  ValidatePasswordPattern,
} from "../../../config/utility";

import { resendCodeFun } from "../../../config/api_calls";

import VerifyUsername from "../../../components/SsoRegister/VerifyUsername";
import GetPincode from "../../../components/SsoRegister/GetPincode";
import GetUserdeteil from "../../../components/SsoRegister/GetUserdeteil";
import GetConsent from "../../../components/SsoRegister/GetConsent";
import SsoSuccess from "../../../components/SsoRegister/Success";

import TextField from "./TextField";
import { tableLoaderSimple } from "../../../components/ContentLoaders/table_loader_simple";
import HelperClass from "./../../../config/helperClass";

import { PhoneNumberUtil } from "google-libphonenumber";

const auth = new HelperClass();

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      comp_name: "",
      title: "",
      email: "",
      username: "",
      password: "",
      currentYear: new Date().getFullYear(),
      disabled: false,
      successMessage: "",
      confirm_password: "",

      disableResendBtn: false,
      phone: "",
      errorPhone: "",
      fieldsHide: false,
      show_form: false,
      resellerData: [],
      tableLoaderSimple: true,
      res_accountno: "",
      res_title: "",
      super_agent_accountno: "",
      agent_title: "",
      action_type: "",
      pageError: "",
      res_url: "",
      url_type: "Simple",
      reseller_url: "",
      step: 0,
      errorMessageEmail: "",
      VerifyUsernameButton: false,
      verifyDetailsButton: false,
      resendPinButton: false,
      successMessageEmail: "",
      errorMessagePassword: "",
      errorMessageConsent: "",
      errorMessageFinal: "",
      selected_email: "",
      pincode: "",
      company_name: "",
      full_name: "",
      primary_contact_name: "",
      notices_email: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      primary_contact_phone: "",
      your_phone: "",
      agree_terms: false,
      errorCompName: "",
      errorFullName: "",
      errorContactName: "",
      errorNoticesEmail: "",
      errorAddress: "",
      errorCity: "",
      errorState: "",
      errorZip: "",
      errorCountry: "",
      errorContactPhone: "",
      errorYourPhone: "",
      errorPassword: "",
      errorConfirmPassword: "",
      api_key: "",
      auth_key: "",
      billing_information: true,
      personal_information: true,
      number_information: true,
      show_resend_button: false,
      resend_pin_text: "",
      countdown: 30,
      user_hash: "",
    };
  }

  async componentDidMount() {
    let reseller_url = this.props.match.params.reseller_url;
    //console.log("reseller_url: ", reseller_url);
    this.setState({
      tableLoaderSimple: true,
    });

    //if (reseller_url !== "") {
    //Errors
    //404 and no_reseller
    //404 and no_agent
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "check_reseller_login",
          JSON.stringify({
            reseller_url: reseller_url,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          //console.log("response.data start : ", response.data);
          if (
            response.data.status === 404 ||
            response.data.status === 422 ||
            response.data.errors !== ""
          ) {
            this.setState({
              errorMessage: "",
              successMessage: "",
              show_form: false,
              pageError:
                "Sorry we are unable to process your request. Please contact admin for more details.",
              tableLoaderSimple: false,
            });
          }

          if (
            response.data.status === 404 &&
            response.data.message === "no_reseller"
          ) {
            this.setState({
              errorMessage: "",
              pageError:
                "Reseller does not exists. Please contact support or try again with correct URL.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
            });
          } else if (response.data.status === 403) {
            this.setState({
              errorMessage: "There is some error.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
              pageError: "",
            });
          } else if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            //SET STEP
            let current_step = localStorage.getItem("step");
            let step = 1;
            if (current_step) {
              current_step = JSON.parse(localStorage.getItem("step"));

              let expiry = current_step.expirationTimestamp;
              const currentTime = new Date().getTime();
              if (currentTime < expiry) {
                step = parseInt(current_step.step);
                //return value;
              } else {
                // Remove the data from local storage if it has expired
                localStorage.removeItem("step");
                let expirationTimestamp = new Date().getTime() + 30 * 60 * 1000;
                localStorage.setItem(
                  "step",
                  JSON.stringify({
                    step: 1,
                    expirationTimestamp: expirationTimestamp,
                  })
                );
                this.setState({
                  email: "",
                  username: "",
                  selected_email: "",
                  successMessageEmail: "",
                });
                step = 1;
              }
            }

            this.set_step_states(step);
            this.setState({
              errorMessage: "",
              return_url: response.data.return_url,
              privacy_url: response.data.privacy_url,
              terms_url: response.data.terms_url,
              tableLoader: false,
              show_form: true,
              pageError: "",
              res_url: reseller_url,
              reseller_url: reseller_url,
              res_hash: response.data.reseller_hash,
              tableLoaderSimple: false,
              reseller_comp: response.data.reseller_comp,
              step: parseInt(step),
            });

            //console.log("this.state.step: ", this.state.step);
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        tableLoaderSimple: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const userHash = urlParams.get("user_hash");
    if (userHash) {
      const new_hash = atob(userHash);
      const UserData = JSON.parse(new_hash);
      const splitName = UserData.email;
      const parts = splitName.split("@");
      const newUserName = parts[0].replace(/[^a-zA-Z0-9]/gi, "");
      this.setState({
        userHash: userHash,
        email: UserData.email,
        username: newUserName,
        company_name: UserData.comp_name,
        full_name: UserData.name,
        primary_contact_name: UserData.name,
        notices_email: UserData.email,
        address: UserData.address,
        city: UserData.city,
        state: UserData.state,
        zip: UserData.zip,
        country: UserData.country,
        primary_contact_phone: UserData.phone,
        your_phone: UserData.phone,
      });
    }
  }

  set_step_states = (step) => {
    //console.log("set_step_states step: ", step);
    let check_username = localStorage.getItem("checkUsername");
    let create_comp = localStorage.getItem("createComp");
    //For Step One
    if (step === 1) {
      localStorage.removeItem("createComp");
      localStorage.removeItem("checkUsername");
      /*if (check_username) {
        check_username = JSON.parse(localStorage.getItem("checkUsername"));
        this.setState({
          email:
            check_username.email && check_username.email !== ""
              ? check_username.email
              : "",
          username:
            check_username.username && check_username.username !== ""
              ? check_username.username
              : "",
          selected_email: "",
          successMessageEmail: "",
        });
      }*/
    }
    //FOR SECOND STEP
    if (step === 2) {
      //let check_username = localStorage.getItem("checkUsername");

      if (check_username) {
        check_username = JSON.parse(localStorage.getItem("checkUsername"));
        this.setState({
          email:
            check_username.email && check_username.email !== ""
              ? check_username.email
              : "",
          username:
            check_username.username && check_username.username !== ""
              ? check_username.username
              : "",
          selected_email:
            check_username.selected_email &&
            check_username.selected_email !== ""
              ? check_username.selected_email
              : "",
          successMessageEmail:
            check_username.successMessageEmail &&
            check_username.successMessageEmail !== ""
              ? check_username.successMessageEmail
              : "",
          show_resend_button: true,
        });
      }
      /**/
    }
    //FOR Third STEP
    if (step === 3) {
      if (check_username) {
        check_username = JSON.parse(localStorage.getItem("checkUsername"));
        this.setState({
          //Previous Step Data
          email:
            check_username.email && check_username.email !== ""
              ? check_username.email
              : "",
          username:
            check_username.username && check_username.username !== ""
              ? check_username.username
              : "",
          selected_email:
            check_username.selected_email &&
            check_username.selected_email !== ""
              ? check_username.selected_email
              : "",
          successMessageEmail:
            check_username.successMessageEmail &&
            check_username.successMessageEmail !== ""
              ? check_username.successMessageEmail
              : "",
        });
      }
      if (create_comp) {
        create_comp = JSON.parse(localStorage.getItem("createComp"));

        this.setState({
          company_name:
            create_comp.company_name && create_comp.company_name !== ""
              ? create_comp.company_name
              : "",
          full_name:
            create_comp.full_name && create_comp.full_name !== ""
              ? create_comp.full_name
              : "",
          primary_contact_name:
            create_comp.primary_contact_name &&
            create_comp.primary_contact_name !== ""
              ? create_comp.primary_contact_name
              : "",
          notices_email:
            create_comp.notices_email && create_comp.notices_email !== ""
              ? create_comp.notices_email
              : "",
          address:
            create_comp.address && create_comp.address !== ""
              ? create_comp.address
              : "",
          city:
            create_comp.city && create_comp.city !== "" ? create_comp.city : "",
          state:
            create_comp.state && create_comp.state !== ""
              ? create_comp.state
              : "",
          zip: create_comp.zip && create_comp.zip !== "" ? create_comp.zip : "",
          country:
            create_comp.country && create_comp.country !== ""
              ? create_comp.country
              : "",
          primary_contact_phone:
            create_comp.primary_contact_phone &&
            create_comp.primary_contact_phone !== ""
              ? create_comp.primary_contact_phone
              : "",
          your_phone:
            create_comp.your_phone && create_comp.your_phone !== ""
              ? create_comp.your_phone
              : "",
          api_key: "",
          auth_key: "",
          company_id: "",
          //Previous Step Data
        });
      }
    }
    if (step === 4) {
      if (create_comp) {
        create_comp = JSON.parse(localStorage.getItem("createComp"));
        this.setState({
          api_key:
            create_comp.api_key && create_comp.api_key !== ""
              ? create_comp.api_key
              : "",
          auth_key:
            create_comp.auth_key && create_comp.auth_key !== ""
              ? create_comp.auth_key
              : "",
          company_id:
            create_comp.company_id && create_comp.company_id !== ""
              ? create_comp.company_id
              : "",
          company_name:
            create_comp.company_name && create_comp.company_name !== ""
              ? create_comp.company_name
              : "",
          full_name:
            create_comp.full_name && create_comp.full_name !== ""
              ? create_comp.full_name
              : "",
          primary_contact_name:
            create_comp.primary_contact_name &&
            create_comp.primary_contact_name !== ""
              ? create_comp.primary_contact_name
              : "",
          notices_email:
            create_comp.notices_email && create_comp.notices_email !== ""
              ? create_comp.notices_email
              : "",
          address:
            create_comp.address && create_comp.address !== ""
              ? create_comp.address
              : "",
          city:
            create_comp.city && create_comp.city !== "" ? create_comp.city : "",
          state:
            create_comp.state && create_comp.state !== ""
              ? create_comp.state
              : "",
          zip: create_comp.zip && create_comp.zip !== "" ? create_comp.zip : "",
          country:
            create_comp.country && create_comp.country !== ""
              ? create_comp.country
              : "",
          primary_contact_phone:
            create_comp.primary_contact_phone &&
            create_comp.primary_contact_phone !== ""
              ? create_comp.primary_contact_phone
              : "",
          your_phone:
            create_comp.your_phone && create_comp.your_phone !== ""
              ? create_comp.your_phone
              : "",
          //Previous Step Data
          email:
            check_username.email && check_username.email !== ""
              ? check_username.email
              : "",
          username:
            check_username.username && check_username.username !== ""
              ? check_username.username
              : "",
          selected_email:
            check_username.selected_email &&
            check_username.selected_email !== ""
              ? check_username.selected_email
              : "",
          successMessageEmail:
            check_username.successMessageEmail &&
            check_username.successMessageEmail !== ""
              ? check_username.successMessageEmail
              : "",
        });
      }
    }
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeCheckbox = (input) => (e) => {
    this.setState({
      [input]:
        e.target.type === "checkbox" ? e.target.checked : e.target.checked,
    });
    //console.log("handleChangeCheckbox: ", input);
  };

  handleChangePhoneNumber = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };
  handleChangePin = (value, index, pincodes) => {
    const newPinCode = [...pincodes];
    newPinCode[index] = value;
    this.setState({ pincode: newPinCode.join("") });
    //console.log("handleChangePin: ", newPinCode.join(""));
  };

  handleChangePinComplete = (value, index, pincodes) => {
    const newPinCode = [...pincodes];
    newPinCode[index] = value;
    this.setState({ pincode: newPinCode.join("") });
    this.verifyPincode();
    //console.log("handleChangePinComplete: ", newPinCode.join(""));
  };

  handleChanges = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  fieldValidationUsername = () => {
    let { username, email } = this.state;

    if (username === "") {
      this.setState({
        errorMessageEmail: "Username is required.",
        VerifyUsernameButton: false,
      });
      return false;
    } else if (email === "") {
      this.setState({
        VerifyUsernameButton: false,
        errorMessageEmail: "Email is required.",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        VerifyUsernameButton: false,
        errorMessageEmail: "Invalid email format.",
      });
      return false;
    } else {
      this.setState({
        errorMessageEmail: "",
        disabled: true,
      });
    }
    return true;
  };

  checkUsername = async () => {
    //e.preventDefault();
    let { username, email, reseller_url } = this.state;
    this.setState({
      VerifyUsernameButton: true,
    });

    if (this.fieldValidationUsername() === true) {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "check_registration_username",
            JSON.stringify({
              username: username,
              email: email,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: reseller_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("response.data : ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessageEmail: "Invalid Email OR Username",
                disabled: false,
                VerifyUsernameButton: false,
              });
            }

            if (response.data.status === 403) {
              this.setState({
                errorMessageEmail: response.data.message,
                disabled: false,
                VerifyUsernameButton: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "Account Already Exists"
            ) {
              this.setState({
                errorMessageEmail: response.data.message,
                disabled: false,
                VerifyUsernameButton: false,
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessageEmail:
                  `We have sent an email to ` +
                  email +
                  `. To complete the verification process, please check your inbox and enter the code you received here.`,
                errorMessageEmail: "",
                fieldsHide: true,
                VerifyUsernameButton: false,
                selected_email: email,
                countdown: 30,
                resend_pin_text: "You can resend the pincode in 30 seconds.",
              });

              localStorage.removeItem("checkUsername");
              localStorage.setItem(
                "checkUsername",
                JSON.stringify({
                  username: username,
                  email: email,
                  selected_email: email,
                  successMessageEmail:
                    `We have sent an email to ` +
                    email +
                    `. To complete the verification process, please check your inbox and enter the code you received here.`,
                  resend_pin_text: "You can resend the pincode in 30 seconds.",
                })
              );
              //show_resend_button
              setTimeout(() => {
                this.setState({
                  show_resend_button: true,
                });
              }, 30000);
              setInterval(() => {
                this.setState((prevState) => ({
                  countdown: prevState.countdown - 1,
                }));
              }, 1000);

              this.nextStep();
            }
          });
      } catch (error) {
        this.setState({
          errorMessageEmail: "There is some error. Please try again later.",
          disabled: false,
          VerifyUsernameButton: false,
        });
        console.log("catch : ", error);
      }
    }
  };

  resendPincode = async () => {
    //e.preventDefault();
    let { username, email, reseller_url } = this.state;

    this.setState({
      resendPinButton: true,
      show_resend_button: false,
    });
    if (this.fieldValidationUsername() === true) {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "resend_pincode",
            JSON.stringify({
              username: username,
              email: email,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: reseller_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("resendPincode response.data : ", response.data);
            if (response.data.status === 404 || response.data.status === 422) {
              this.setState({
                errorMessagePassword: "Invalid Email OR Username",
                disabled: false,
                resendPinButton: false,
                successMessageEmail: "",
                show_resend_button: true,
              });
              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
            }

            if (response.data.status === 403) {
              this.setState({
                errorMessagePassword: response.data.message,
                disabled: false,
                resendPinButton: false,
                successMessageEmail: "",
                show_resend_button: true,
              });
              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
            }

            if (
              response.data.status === 200 &&
              response.data.message === "Account Already Exists"
            ) {
              this.setState({
                errorMessagePassword: response.data.message,
                disabled: false,
                resendPinButton: false,
                successMessageEmail: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessageEmail:
                  `We have sent an email to ` +
                  email +
                  `. To complete the verification process, please check your inbox and enter the code you received here.`,
                errorMessagePassword: "",
                fieldsHide: true,
                resendPinButton: false,
                resend_pin_text: "You can resend the pincode in 60 seconds.",
                countdown: 60,
              });

              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
              setTimeout(() => {
                this.setState({
                  show_resend_button: true,
                });
              }, 60000);
              setInterval(() => {
                this.setState((prevState) => ({
                  countdown: prevState.countdown - 1,
                }));
              }, 1000);
            }
          });
      } catch (error) {
        this.setState({
          errorMessagePassword: "There is some error. Please try again later.",
          disabled: false,
          successMessageEmail: "",
          show_resend_button: true,
          resendPinButton: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessagePassword: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    }
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      console.log("tatta", error);
      return false;
    }
  };

  verifyPincode = async () => {
    //e.preventDefault();
    let { username, email, pincode, reseller_url } = this.state;

    this.setState({
      verifyPasswordButton: true,
    });
    if (this.fieldValidationUsername() === true) {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "verify_pincode",
            JSON.stringify({
              username: username,
              email: email,
              pincode: pincode,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: reseller_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("verifyPincode response.data : ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessagePassword:
                  "There is some error while verifying the pincode.",
                disabled: false,
                verifyPasswordButton: false,
                successMessageEmail: "",
              });
              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
            }

            if (response.data.status === 403) {
              this.setState({
                errorMessagePassword: response.data.message,
                disabled: false,
                verifyPasswordButton: false,
                successMessageEmail: "",
              });
              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessageEmail: "",
                errorMessagePassword: "",
                verifyPasswordButton: false,
              });
              this.nextStep();
            }
          });
      } catch (error) {
        this.setState({
          errorMessagePassword: "There is some error. Please try again later.",
          disabled: false,
          successMessageEmail: "",
          verifyPasswordButton: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessagePassword: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    }
  };

  fieldValidation = () => {
    let {
      name,
      email,
      password,
      confirm_password,
      company_name,
      full_name,
      primary_contact_name,
      notices_email,
      address,
      city,
      state,
      zip,
      country,
      primary_contact_phone,
      your_phone,
      agree_terms,
    } = this.state;
    if (company_name === "") {
      this.setState({
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorCompName: "error",
        errorMessageConsent: "Company Name is required.",
        verifyDetailsButton: false,
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (full_name === "") {
      this.setState({
        errorCompName: "",
        verifyDetailsButton: false,
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorFullName: "error",
        errorMessageConsent: "Full Name is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (primary_contact_name === "") {
      this.setState({
        errorContactName: "error",
        errorCompName: "",
        errorFullName: "",
        verifyDetailsButton: false,
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Primary contact name is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (notices_email === "") {
      this.setState({
        errorNoticesEmail: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        verifyDetailsButton: false,
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Notices email is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (ValidateEmail(notices_email) === false) {
      this.setState({
        errorNoticesEmail: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        verifyDetailsButton: false,
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Invalid email format.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (address === "") {
      this.setState({
        errorAddress: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        verifyDetailsButton: false,
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Address is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (city === "") {
      this.setState({
        errorCity: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        verifyDetailsButton: false,
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "City is required.",
        errorcPass: "",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (state === "") {
      this.setState({
        errorState: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        verifyDetailsButton: false,
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "State is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (zip === "") {
      this.setState({
        errorZip: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        verifyDetailsButton: false,
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Zip code is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (country === "" || country === null) {
      this.setState({
        errorCountry: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        verifyDetailsButton: false,
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Country is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (primary_contact_phone === "") {
      this.setState({
        errorContactPhone: "Primary contact phone is required.",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        verifyDetailsButton: false,
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Primary contact phone is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (!this.isPhoneValid(primary_contact_phone)) {
      this.setState({
        errorContactPhone: "Invalid contact phone format.",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        verifyDetailsButton: false,
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Invalid contact phone format.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (your_phone === "") {
      this.setState({
        errorYourPhone: "Your phone is required.",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        verifyDetailsButton: false,
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Your phone is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (!this.isPhoneValid(your_phone)) {
      this.setState({
        errorYourPhone: "Invalid your phone format.",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        verifyDetailsButton: false,
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "Invalid your phone format.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (password === "") {
      this.setState({
        errorPassword: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        verifyDetailsButton: false,
        errorConfirmPassword: "",
        errorMessageConsent: "Password is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (password.length < 8) {
      this.setState({
        errorPassword: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        verifyDetailsButton: false,
        errorConfirmPassword: "",
        errorMessageConsent: "Use 8 characters or more for your password",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (ValidatePasswordPattern(password) === false) {
      this.setState({
        errorPassword: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        verifyDetailsButton: false,
        errorConfirmPassword: "",
        errorMessageConsent:
          "Password should contain at least 1 number, 1 capital letter, 1 special character, and be at least 8 characters long.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (confirm_password === "") {
      this.setState({
        errorConfirmPassword: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        verifyDetailsButton: false,
        errorMessageConsent: "Confirm password is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorConfirmPassword: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorMessageConsent:
          "Use 8 characters or more for your confirm password",
        verifyDetailsButton: false,
      });

      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (password !== confirm_password) {
      this.setState({
        errorPassword: "error",
        errorConfirmPassword: "error",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        verifyDetailsButton: false,
        errorMessageConsent: "Password and confirm password does not match.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (agree_terms === false) {
      this.setState({
        errorPassword: "",
        errorConfirmPassword: "",
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        verifyDetailsButton: false,
        errorMessageConsent: "You must agree to terms and conditions.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else {
      this.setState({
        errorCompName: "",
        errorFullName: "",
        errorContactName: "",
        errorNoticesEmail: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorContactPhone: "",
        errorYourPhone: "",
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "",

        disabled: true,
      });
    }
    return true;
  };

  formSubmit = async () => {
    //e.preventDefault();
    let {
      username,
      email,
      password,
      confirm_password,
      company_name,
      full_name,
      primary_contact_name,
      notices_email,
      address,
      city,
      state,
      zip,
      country,
      primary_contact_phone,
      your_phone,
      agree_terms,
      reseller_url,
    } = this.state;
    this.setState({
      verifyDetailsButton: true,
    });

    if (this.fieldValidation() === true) {
      //console.log("agree_terms: ", agree_terms);

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "register_company",
            JSON.stringify({
              username: username,
              email: email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              company_name: company_name,
              full_name: full_name,
              primary_contact_name: primary_contact_name,
              notices_email: notices_email,
              address: address,
              city: city,
              state: state,
              zip: zip,
              country: country,
              primary_contact_phone: primary_contact_phone,
              your_phone: your_phone,
              agree_terms: agree_terms,
              reseller_url: reseller_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("formSubmit response.data : ", response.data);
            if (response.data.status === 404 || response.data.status === 422) {
              this.setState({
                errorMessageConsent: "Invalid Email OR Password",
                disabled: false,
                verifyDetailsButton: false,
              });
              document
                .getElementById("startContainer")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              return false;
            }

            if (
              response.data.status === 200 &&
              response.data.message === "Account Already Exists"
            ) {
              this.setState({
                errorMessageConsent: response.data.message,
                disabled: false,
                verifyDetailsButton: false,
              });
              document
                .getElementById("startContainer")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              return false;
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage: "",
                errorMessageConsent: "",
                api_key: response.data.api_key,
                auth_key: response.data.auth_key,
                company_id: response.data.company_id,
                fieldsHide: true,
                verifyDetailsButton: false,
                user_hash: response.data.hash,
              });
              ////
              var existingArray =
                JSON.parse(localStorage.getItem("loggedin_accounts")) || [];
              var valueExists =
                $.grep(existingArray, function (item) {
                  return item.email === response.data.user_email;
                }).length > 0;
              if (!valueExists) {
                existingArray.push({
                  email: response.data.user_email,
                  hash: response.data.hash,
                  expiry: response.data.expiry,
                  company_id: response.data.company_id,
                });
              } else {
                //Remove existing value and push new values in array
                existingArray = existingArray.filter(
                  (item) => item.email !== response.data.user_email
                );
                existingArray.push({
                  email: response.data.user_email,
                  hash: response.data.hash,
                  expiry: response.data.expiry,
                  company_id: response.data.company_id,
                });
              }
              localStorage.setItem(
                "loggedin_accounts",
                JSON.stringify(existingArray)
              );

              ///////adding usr in local storage
              ////

              localStorage.removeItem("createComp");
              localStorage.setItem(
                "createComp",
                JSON.stringify({
                  api_key: response.data.api_key,
                  auth_key: response.data.auth_key,
                  company_name: company_name,
                  full_name: full_name,
                  primary_contact_name: primary_contact_name,
                  notices_email: notices_email,
                  address: address,
                  city: city,
                  state: state,
                  zip: zip,
                  country: country,
                  primary_contact_phone: primary_contact_phone,
                  your_phone: your_phone,
                  company_id: response.data.company_id,
                })
              );
              this.nextStep();
            }
          });
      } catch (error) {
        this.setState({
          errorMessageConsent:
            "There is some error while creating your account.",
          disabled: false,
          verifyDetailsButton: false,
        });
        document
          .getElementById("startContainer")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
        console.log("catch : ", error);
      }
    }
  };

  submitConsent = async () => {
    let {
      email,
      username,
      return_url,
      res_url,
      company_id,
      api_key,
      auth_key,
      user_hash,
    } = this.state;
    this.setState({
      verifyConsentButton: true,
    });
    if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessageConsent: "Email is required.",
        verifyConsentButton: false,
      });
    } else {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessageConsent: "",
        disabled: true,
        loader: "show",
      });
      //console.log("company_id: ", company_id);
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "submit_consent",
            JSON.stringify({
              email: email,
              return_url: return_url,
              reseller_hash: res_url,
              reseller_url: res_url,
              company_id: company_id,
              username: username,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("return_url: ", return_url);
            //console.log("CONSENT DETAILS: ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessageConsent:
                  "There is some error. Please try again later",
                disabled: false,
                verifyConsentButton: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Please wait, redirecting you to the dashboard.",
                errorMessageConsent: "",
                verifyConsentButton: false,
              });
              localStorage.removeItem("createComp");
              localStorage.removeItem("checkUsername");
              localStorage.removeItem("step");
              //this.nextStep();
              //window.location.replace("/login/" + res_url + "/" + company_id);
              // setTimeout(function () {
              //   window.close();
              // }, 5000);
              setTimeout(function () {
                window.location.replace("/login/" + res_url);
              }, 3000);
            }
          });
      } catch (error) {
        this.setState({
          successMessage: "",
          errorMessageConsent: "There is some error. please try again later.",
          verifyConsentButton: false,
        });
        console.log("catch : ", error);
      }
    }
  };

  resendEmail = async () => {
    let { email } = this.state;

    if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Email is required.",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      return false;
    } else {
      this.setState({
        errorEmail: "",
        errorMessage: "",
        disableResendBtn: true,
      });

      let resendRes = await resendCodeFun(email);
      //console.log("resendRes: ", resendRes.data);
      if (resendRes.data.status === 404 || resendRes.data.errors !== "") {
        this.setState({
          errorMessage: resendRes.data.errors,
          disableResendBtn: false,
          successMessage: "",
        });
      } else if (
        resendRes.data.status === 200 &&
        resendRes.data.message === "Success" &&
        resendRes.data.errors === ""
      ) {
        this.setState({
          successMessage: `Email Sent.Please check inbox/spam for account activation.`,
          errorMessage: "",
          disableResendBtn: false,
        });
      } else {
        window.location.replace("/error");
      }
    }
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
    localStorage.removeItem("step");
    //15 Minutes Expiry
    let expirationTimestamp = new Date().getTime() + 30 * 60 * 1000;
    localStorage.setItem(
      "step",
      JSON.stringify({
        step: step + 1,
        expirationTimestamp: expirationTimestamp,
      })
    );
    //console.log("STEP: ", this.state.step);
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
    localStorage.removeItem("step");
    //15 Minutes Expiry
    let expirationTimestamp = new Date().getTime() + 30 * 60 * 1000;
    localStorage.setItem(
      "step",
      JSON.stringify({
        step: step - 1,
        expirationTimestamp: expirationTimestamp,
      })
    );
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
    localStorage.removeItem("step");
    //15 Minutes Expiry
    let expirationTimestamp = new Date().getTime() + 30 * 60 * 1000;
    localStorage.setItem(
      "step",
      JSON.stringify({
        step: 1,
        expirationTimestamp: expirationTimestamp,
      })
    );
  };

  refreshForm = () => {
    localStorage.removeItem("createComp");
    localStorage.removeItem("checkUsername");
    this.setState({
      username: "",
      email: "",
    });
    this.firstStep();
  };

  refreshFormReload = () => {
    localStorage.removeItem("createComp");
    localStorage.removeItem("checkUsername");
    localStorage.removeItem("step");
    this.setState({
      username: "",
      email: "",
    });
    window.location.reload();
  };

  render() {
    const { step } = this.state;
    //console.log("Rendered Step: ", step);
    const {
      email,
      username,
      pincode,
      errorMessage,
      successMessage,
      selected_email,
      VerifyUsernameButton,
      verifyPasswordButton,
      verifyConsentButton,
      verifyDetailsButton,
      billing_information,
      personal_information,
      number_information,
      resendPinButton,
      password,
      confirm_password,
      return_url,
      privacy_url,
      terms_url,
      reseller_comp,
      company_id,
      company_name,
      full_name,
      primary_contact_name,
      notices_email,
      address,
      city,
      state,
      zip,
      country,
      primary_contact_phone,
      your_phone,
      agree_terms,
      errorCompName,
      errorFullName,
      errorContactName,
      errorNoticesEmail,
      errorAddress,
      errorCity,
      errorState,
      errorZip,
      errorCountry,
      errorContactPhone,
      errorYourPhone,
      errorPassword,
      errorConfirmPassword,
      api_key,
      auth_key,
      show_resend_button,
      resend_pin_text,
      countdown,
    } = this.state;
    const values = {
      email,
      username,
      pincode,
      errorMessage,
      successMessage,
      selected_email,
      VerifyUsernameButton,
      verifyPasswordButton,
      verifyDetailsButton,
      verifyConsentButton,
      billing_information,
      personal_information,
      number_information,
      resendPinButton,
      password,
      confirm_password,
      return_url,
      privacy_url,
      terms_url,
      reseller_comp,
      company_id,
      company_name,
      full_name,
      primary_contact_name,
      notices_email,
      address,
      city,
      state,
      zip,
      country,
      primary_contact_phone,
      your_phone,
      agree_terms,
      errorCompName,
      errorFullName,
      errorContactName,
      errorNoticesEmail,
      errorAddress,
      errorCity,
      errorState,
      errorZip,
      errorCountry,
      errorContactPhone,
      errorYourPhone,
      errorPassword,
      errorConfirmPassword,
      api_key,
      auth_key,
      show_resend_button,
      resend_pin_text,
      countdown,
    };
    //console.log("fieldsHide: ", this.state.fieldsHide);
    return (
      <div className="nk-body bg-white npc-general pg-auth" id="startContainer">
        <div className="nk-app-root">
          <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
              <div className="nk-content">
                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                  {this.state.pageError !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.pageError}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {this.state.tableLoaderSimple === true ? (
                        tableLoaderSimple()
                      ) : (
                        <>
                          <div className="brand-logo pb-4 text-center">
                            <Link
                              color="inherit"
                              to={`/login/${this.state.res_url}`}
                              className="logo-link"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <img
                                className="logo-light logo-img logo-img-lg"
                                src={LOGO}
                                alt="logo"
                              />
                              <img
                                className="logo-dark logo-img logo-img-lg"
                                src={LOGO}
                                alt="logo-dark"
                              />
                            </Link>
                          </div>
                          <div
                            className="card card-bordered"
                            id="login_form_main"
                          >
                            <div className="card-inner">
                              {(() => {
                                switch (step) {
                                  default:
                                    return (
                                      <div className="wide-xs mx-auto text-center">
                                        <h3 className="nk-error-title">
                                          Oops! There is some error
                                        </h3>
                                        <p className="nk-error-text">
                                          We are very sorry for inconvenience.
                                          It looks like the page you are trying
                                          to access is having problems. Please
                                          try again later.
                                        </p>
                                      </div>
                                    );
                                  case 1:
                                    return (
                                      <>
                                        {this.state.errorMessageEmail !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {this.state.errorMessageEmail}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <VerifyUsername
                                          nextStep={this.nextStep}
                                          handleChange={this.handleChange}
                                          checkUsername={this.checkUsername}
                                          values={values}
                                        />
                                      </>
                                    );
                                  case 2:
                                    return (
                                      <>
                                        {this.state.errorMessagePassword !==
                                        "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessagePassword
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessageEmail !==
                                        "" ? (
                                          <div
                                            className="example-alert mb-3"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .successMessageEmail
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <GetPincode
                                          nextStep={this.nextStep}
                                          prevStep={this.prevStep}
                                          handleChangePin={this.handleChangePin}
                                          handleChangePinComplete={
                                            this.handleChangePinComplete
                                          }
                                          verifyPincode={this.verifyPincode}
                                          resendPincode={this.resendPincode}
                                          addMenuHistory={this.addMenuHistory}
                                          values={values}
                                        />
                                      </>
                                    );
                                  case 3:
                                    return (
                                      <>
                                        {this.state.errorMessageConsent !==
                                        "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageConsent
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <GetUserdeteil
                                          nextStep={this.nextStep}
                                          prevStep={this.prevStep}
                                          handleChange={this.handleChange}
                                          handleChanges={this.handleChanges}
                                          handleChangeCheckbox={
                                            this.handleChangeCheckbox
                                          }
                                          handleChangePhoneNumber={
                                            this.handleChangePhoneNumber
                                          }
                                          formSubmit={this.formSubmit}
                                          values={values}
                                        />
                                      </>
                                    );
                                  case 4:
                                    return (
                                      <>
                                        {this.state.errorMessageFinal !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {this.state.errorMessageFinal}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <GetConsent
                                          nextStep={this.nextStep}
                                          prevStep={this.prevStep}
                                          firstStep={this.firstStep}
                                          handleChange={this.handleChange}
                                          submitConsent={this.submitConsent}
                                          values={values}
                                        />
                                      </>
                                    );
                                  //
                                  case 5:
                                    return (
                                      <SsoSuccess
                                        firstStep={this.firstStep}
                                        refreshFormReload={
                                          this.refreshFormReload
                                        }
                                      />
                                    );
                                }
                              })()}
                              {this.state.step < 4 ? (
                                <div className="form-note-s2 text-center pt-4">
                                  {" "}
                                  Already have an account?{" "}
                                  <Link
                                    color="inherit"
                                    to={`/login/${this.state.reseller_url}`}
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    Sign In
                                  </Link>
                                </div>
                              ) : null}
                              {this.state.step > 2 && this.state.step < 4 ? (
                                <div className="form-note-s2 text-center pt-4">
                                  {" "}
                                  <Link
                                    color="inherit"
                                    onClick={this.refreshForm}
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    Start Again
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="nk-footer nk-auth-footer-full">
                  <div className="container wide-lg">
                    <div className="row g-3 text-center">
                      <div className="col-lg-12">
                        <div className="nk-block-content text-center ">
                          <p className="text-soft">
                            &copy; {this.state.currentYear} {COMPANY_NAME}. All
                            Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="nk-app-root">
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn btn-white btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  {auth.getResellerLogo() !== "" ? (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                        alt="logo-dark"
                      />
                    </a>
                  ) : (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo-dark"
                      />
                    </a>
                  )}
                </div>
                {this.state.pageError !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.pageError}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.tableLoader === true ? (
                      tableLoader()
                    ) : (
                      <>
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            {this.state.action_type === "sub" ? (
                              <h5 className="nk-block-title">
                                Register Sub Agent of {this.state.agent_title}{" "}
                                for {this.state.res_title}
                              </h5>
                            ) : null}
                            {this.state.action_type !== "sub" ? (
                              <h5 className="nk-block-title">
                                Register Agent of {this.state.res_title}
                              </h5>
                            ) : null}
                            <div className="nk-block-des">
                              {this.state.errorMessage !== "" ? (
                                <div className="example-alert">
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.successMessage !== "" ? (
                                <div className="example-alert">
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.successMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <form
                          method="post"
                          className="registerForm"
                          onSubmit={this.formSubmit}
                        >
                          {this.state.fieldsHide === false ? (
                            <div>
                              <TextField
                                label="Company Name"
                                error={this.state.errorCompName}
                                id="comp_name"
                                name="comp_name"
                                value={this.state.comp_name}
                                placeholder="Company name"
                                change={this.handleChange}
                              />
                              <TextField
                                label="Full Name"
                                error={this.state.errorName}
                                id="name"
                                name="name"
                                value={this.state.name}
                                placeholder="Full Name"
                                change={this.handleChange}
                              />
                              <TextField
                                label="Title"
                                error={this.state.errorTitle}
                                id="title"
                                name="title"
                                value={this.state.title}
                                placeholder="Title"
                                change={this.handleChange}
                              />

                              <TextField
                                label="Email"
                                type="text"
                                error={this.state.errorEmail}
                                id="email"
                                name="email"
                                value={this.state.email}
                                placeholder="Enter your email address"
                                change={this.handleChange}
                              />

                              <TextField
                                label="Phone"
                                type="number"
                                error={this.state.errorPhone}
                                id="phone"
                                name="phone"
                                value={this.state.phone}
                                placeholder="Enter your phone"
                                change={this.handleChange}
                              />
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="password"
                                >
                                  Password
                                </label>
                                <div className="form-control-wrap">
                                  <a
                                    tabIndex="-1"
                                    href="#"
                                    className="form-icon form-icon-right passcode-switch"
                                    data-target="password"
                                  >
                                    <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                    <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                  </a>
                                  <input
                                    type="password"
                                    className={
                                      "form-control form-control-lg " +
                                      this.state.errorPass
                                    }
                                    id="password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    placeholder="Enter your password"
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="confirm_password"
                                >
                                  Confirm Password
                                </label>
                                <div className="form-control-wrap">
                                  <a
                                    tabIndex="-1"
                                    href="#"
                                    className="form-icon form-icon-right passcode-switch"
                                    data-target="confirm_password"
                                  >
                                    <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                    <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                  </a>
                                  <input
                                    type="password"
                                    className={
                                      "form-control form-control-lg " +
                                      this.state.errorcPass
                                    }
                                    id="confirm_password"
                                    name="confirm_password"
                                    value={this.state.confirm_password}
                                    onChange={this.handleChange}
                                    placeholder="Confirm your password"
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <br />

                          <div className="form-group">
                            <button
                              className="btn btn btn-dim btn-primary"
                              onClick={() => this.resendEmail()}
                              disabled={this.state.disableResendBtn}
                              id="resendBtn"
                              style={{ display: "none" }}
                            >
                              Resend Email
                            </button>{" "}
                            <button
                              id="signupBtn"
                              className="btn btn-lg btn-block"
                              type="submit"
                              disabled={this.state.disabled}
                              style={{
                                backgroundColor: THEME_COLOR,
                                color: "white",
                              }}
                            >
                              Register
                            </button>
                          </div>
                        </form>
                      </>
                    )}

                    <div className="form-note-s2 pt-4">
                      {" "}
                      Already have an account ?{" "}
                      {auth.getUrlType() === "Simple" ? (
                        <a href={`/${this.state.res_url}`}>
                          <strong>Sign in instead</strong>
                        </a>
                      ) : (
                        <a href="/">
                          <strong>Sign in instead</strong>
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="nk-block nk-auth-footer">
                <div className="mt-3">
                  <p>
                    &copy; 2022-2023{" "}
                    <a
                      href="https://onboardsoft.com/"
                      style={{ color: "#526484" }}
                    >
                      OnBoardsoft LLC
                    </a>
                    . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div
                  className="slider-init"
                  data-slick='{"dots":true, "arrows":false}'
                >
                  <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_A} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>
                          {this.state.res_title !== "" ? (
                            <>
                              {this.state.res_title} Agents / Channel Partners
                            </>
                          ) : (
                            <>{COMPANY_NAME}</>
                          )}
                        </h4>
                        <p>
                          Enter details here to signup and start using our
                          exciting product.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-dots"></div>
                <div className="slider-arrows"></div>
              </div>
            </div>
          </div>
        </div>
                          */}
      </div>
    );
  }
}
