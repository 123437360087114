import React, { Component, Fragment } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  MuiThemeProvider,
} from "@material-ui/core";
import { HELP_SUPPORT, CONTACT_US_URL } from "../../config/config";

export class DomainSuccess extends Component {
  first = (e) => {
    // window.close();
    // setTimeout(() => {
    //   //window.location.reload();
    //   //this.props.firstStep();
    // }, 1000);
  };
  goToDashboard = async () => {
    const { values, goToVoyce } = this.props;
    await goToVoyce();
  };
  render() {
    return (
      <>
        <div className="nk-block-head justify-content-center  mb-4">
          <div className="nk-block-head-content text-center">
            {/* <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
            <h4 className="nk-block-title text-center mt-4">
              Login Successful
            </h4> */}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <p>
            Welcome back! Your login was successful.
            <br />
            <br />
            We're thrilled to see you again. You are now logged in and can
            access all the features and services available to you.
            <br />
            Remember, if you ever need help while navigating our platform, our{" "}
            <a href={HELP_SUPPORT} target="_blank">
              Help & Support
            </a>{" "}
            page is just a click away.
            <br />
            For any further queries, feel free to get in touch with us via our{" "}
            <a href={CONTACT_US_URL} target="_blank">
              Contact Us
            </a>{" "}
            page.
            <br />
            Please note, for your security, this window will close shortly...
            <br />
          </p>
        </div>
        <div className="form-group d-flex justify-content-end mt-4">
          <button
            className="btn btn-lg btn-primary btn-block"
            onClick={this.goToDashboard}
            id="closePopup"
          >
            {/* <em className="icon ni ni-first"></em> */}
            Launch Voyce Dashboard
          </button>
        </div>
      </>
    );
  }
}

export default DomainSuccess;
