import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import { forgotEmailSend } from "../../../config/api_calls";
import { ValidateEmail, axiosConfig } from "../../../config/utility";
import { tableLoaderSimple } from "../../../components/ContentLoaders/table_loader_simple";
import {
  LOGO,
  THEME_COLOR,
  THEME_TEXT_COLOR,
  COMPANY_NAME,
} from "../../../config/config";

import VerifyEmail from "../../../components/SsoForgot/VerifyEmail";
import GetPincode from "../../../components/SsoForgot/GetPincode";
import GetPassword from "../../../components/SsoForgot/GetPassword";
import SsoSuccess from "../../../components/SsoForgot/Success";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import helpherClass from "../../../config/helperClass";
const auth = new helpherClass();

export default class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorEmail: "",
      errorMessage: "",
      successMessage: "",
      btnDisable: false,
      res_url: "",
      step: 1,
      errorMessageEmail: "",
      errorMessagePassword: "",
      errorMessageConsent: "",
      selected_email: "",
      pageError: "",
      pincode: "",
      resendPinButton: false,
      show_resend_button: false,
      resend_pin_text: "",
      countdown: 30,
      verifyPasswordButton: false,
      verifyDetailsButton: false,
      successMessageEmail: "",
      password: "",
      confirm_password: "",
      currentYear: new Date().getFullYear(),
      reseller_url: "",
    };
  }

  async componentDidMount() {
    this.setState({
      res_url: auth.getResellerIdentifier(),
    });
    let reseller_url = this.props.match.params.reseller_url;
    this.setState({
      tableLoaderSimple: true,
    });

    //if (reseller_url !== "") {
    //Errors
    //404 and no_reseller
    //404 and no_agent
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "check_reseller_login",
          JSON.stringify({
            reseller_url: reseller_url,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          ///console.log("response.data start : ", response.data);
          if (
            response.data.status === 404 ||
            response.data.status === 422 ||
            response.data.errors !== ""
          ) {
            this.setState({
              errorMessage: "",
              successMessage: "",
              show_form: false,
              pageError:
                "Sorry we are unable to process your request. Please contact admin for more details.",
              tableLoaderSimple: false,
            });
          }

          if (
            response.data.status === 404 &&
            response.data.message === "no_reseller"
          ) {
            this.setState({
              errorMessage: "",
              pageError:
                "Reseller does not exists. Please contact support or try again with correct URL.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
            });
          } else if (response.data.status === 403) {
            this.setState({
              errorMessage: "There is some error.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
              pageError: "",
            });
          } else if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            //console.log("response.data.url_type: ", response.data.url_type);
            Cookies.set(
              "resellerhash__",
              CryptoJS.AES.encrypt(
                reseller_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resellerreturnurl__",
              CryptoJS.AES.encrypt(
                response.data.return_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resellerprivacyurl__",
              CryptoJS.AES.encrypt(
                response.data.privacy_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "reslogo__",
              CryptoJS.AES.encrypt(
                response.data.reseller_logo,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resellertermsurl__",
              CryptoJS.AES.encrypt(
                response.data.terms_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resellercompanyname__",
              CryptoJS.AES.encrypt(
                response.data.reseller_comp,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            this.setState({
              errorMessage: "",
              return_url: response.data.return_url,
              privacy_url: response.data.privacy_url,
              terms_url: response.data.terms_url,
              tableLoader: false,
              show_form: true,
              pageError: "",
              res_url: reseller_url,
              reseller_url: reseller_url,
              res_hash: response.data.reseller_hash,
              tableLoaderSimple: false,
              reseller_comp: response.data.reseller_comp,
            });
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        tableLoaderSimple: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }
  }

  fieldValidation = () => {
    let { password, confirm_password } = this.state;

    if (password === "") {
      this.setState({
        errorPassword: "error",
        errorConfirmPassword: "",
        errorMessageConsent: "Password is required.",
        verifyDetailsButton: false,
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (password.length < 8) {
      this.setState({
        errorPassword: "error",
        errorConfirmPassword: "",
        errorMessageConsent: "Use 8 characters or more for your password",
        verifyDetailsButton: false,
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (confirm_password === "") {
      this.setState({
        errorConfirmPassword: "error",
        errorPassword: "",
        verifyDetailsButton: false,
        errorMessageConsent: "Confirm password is required.",
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorConfirmPassword: "error",
        errorPassword: "",
        errorMessageConsent:
          "Use 8 characters or more for your confirm password",
        verifyDetailsButton: false,
      });

      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (password !== confirm_password) {
      this.setState({
        errorPassword: "error",
        errorConfirmPassword: "error",
        errorMessageConsent: "Password and confirm password does not match.",
        verifyDetailsButton: false,
      });
      document
        .getElementById("startContainer")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else {
      this.setState({
        errorPassword: "",
        errorConfirmPassword: "",
        errorMessageConsent: "",
        disabled: true,
      });
    }
    return true;
  };

  formSubmit = async () => {
    //e.preventDefault();
    let {
      user_name,
      selected_email,
      password,
      confirm_password,
      reseller_url,
    } = this.state;

    this.setState({
      verifyDetailsButton: true,
    });
    if (this.fieldValidation() === true) {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "update_password",
            JSON.stringify({
              username: user_name,
              email: selected_email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: reseller_url,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("formSubmit response.data : ", response.data);
            if (response.data.status === 404 || response.data.status === 422) {
              this.setState({
                errorMessageConsent: "Invalid Email OR Password",
                disabled: false,
                verifyDetailsButton: false,
              });
              document
                .getElementById("startContainer")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              return false;
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage: "",
                errorMessageConsent: "",
                fieldsHide: true,
                verifyDetailsButton: false,
              });

              this.nextStep();
            }
          });
      } catch (error) {
        this.setState({
          errorMessageConsent:
            "There is some error while creating your account.",
          disabled: false,
          verifyDetailsButton: false,
        });
        document
          .getElementById("startContainer")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
        console.log("catch : ", error);
      }
    }
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChanges = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangePin = (value, index, pincodes) => {
    const newPinCode = [...pincodes];
    newPinCode[index] = value;
    this.setState({ pincode: newPinCode.join("") });
    //console.log("handleChangePin: ", newPinCode.join(""));
  };

  handleChangePinComplete = (value, index, pincodes) => {
    const newPinCode = [...pincodes];
    newPinCode[index] = value;
    this.setState({ pincode: newPinCode.join("") });
    this.verifyPincode();
    //console.log("handleChangePinComplete: ", newPinCode.join(""));
  };

  checkUsername = async () => {
    let { email, selected_email, res_url } = this.state;
    this.setState({
      verifyEmailButton: true,
    });
    if (email !== "") {
      try {
        await axios
          .post(
            process.env.REACT_APP_API_URL + "check_forget_username",
            JSON.stringify({
              email: email,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: res_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("LOGIN DETAILS: ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessageEmail: response.data.errors,
                disabled: false,
                successMessageEmail: "",
                verifyEmailButton: false,
              });
              setTimeout(() => {
                this.setState({
                  errorMessageEmail: "",
                });
              }, 4000);
              //return false;
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessageEmail:
                  `We have sent an email to ` +
                  response.data.user_email +
                  `. To complete the verification process, please check your inbox and enter the code you received here.`,
                selected_email: response.data.user_email,
                user_name: response.data.user_name,
                company_id: response.data.company_id,
                errorMessageEmail: "",
                verifyEmailButton: false,
              });
              setTimeout(() => {
                this.setState({
                  show_resend_button: true,
                });
              }, 30000);
              this.timer = setInterval(() => {
                this.setState((prevState) => ({
                  countdown: prevState.countdown - 1,
                }));
                if (this.state.countdown === 0) {
                  //this.setState({ showText: true });
                  clearInterval(this.timer); // Stop the timer when the text is shown
                }
              }, 1000);
              this.nextStep();
              //return true;
            }
          });
      } catch (error) {
        //return false;
        this.setState({
          errorMessageEmail: "There is some error while checking the username.",
          verifyEmailButton: false,
          successMessageEmail: "",
        });
        setTimeout(() => {
          this.setState({
            errorMessageEmail: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessageEmail: "Please enter the username or email.",
        verifyEmailButton: false,
        successMessageEmail: "",
      });
      setTimeout(() => {
        this.setState({
          errorMessageEmail: "",
        });
      }, 4000);
    }
    //return false;
  };

  fieldValidationUsername = () => {
    let { user_name, selected_email } = this.state;

    if (user_name === "") {
      this.setState({
        errorMessageEmail: "Username is required.",
        VerifyUsernameButton: false,
      });
      return false;
    } else if (selected_email === "") {
      this.setState({
        VerifyUsernameButton: false,
        errorMessageEmail: "Email is required.",
      });
      return false;
    } else if (ValidateEmail(selected_email) === false) {
      this.setState({
        VerifyUsernameButton: false,
        errorMessageEmail: "Invalid email format.",
      });
      return false;
    } else {
      this.setState({
        errorMessageEmail: "",
        disabled: true,
      });
    }
    return true;
  };

  resendPincode = async () => {
    //e.preventDefault();
    let { user_name, selected_email, res_url } = this.state;

    this.setState({
      resendPinButton: true,
      show_resend_button: false,
    });
    if (this.fieldValidationUsername() === true) {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "resend_pincode",
            JSON.stringify({
              username: user_name,
              email: selected_email,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: res_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("resendPincode response.data : ", response.data);
            if (response.data.status === 404 || response.data.status === 422) {
              this.setState({
                errorMessagePassword: "Invalid Email OR Username",
                disabled: false,
                resendPinButton: false,
                successMessageEmail: "",
                show_resend_button: true,
              });
              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
            }

            if (response.data.status === 403) {
              this.setState({
                errorMessagePassword: response.data.message,
                disabled: false,
                resendPinButton: false,
                successMessageEmail: "",
                show_resend_button: true,
              });
              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
            }

            if (
              response.data.status === 200 &&
              response.data.message === "Account Already Exists"
            ) {
              this.setState({
                errorMessagePassword: response.data.message,
                disabled: false,
                resendPinButton: false,
                successMessageEmail: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessageEmail:
                  `We have sent an email to ` +
                  selected_email +
                  `. To complete the verification process, please check your inbox and enter the code you received here.`,
                errorMessagePassword: "",
                fieldsHide: true,
                resendPinButton: false,
                resend_pin_text: "You can resend the pincode in 60 seconds.",
                countdown: 60,
              });

              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
              setTimeout(() => {
                this.setState({
                  show_resend_button: true,
                });
              }, 60000);
              this.timer = setInterval(() => {
                this.setState((prevState) => ({
                  countdown: prevState.countdown - 1,
                }));
                if (this.state.countdown === 0) {
                  //this.setState({ showText: true });
                  clearInterval(this.timer); // Stop the timer when the text is shown
                }
              }, 1000);
            }
          });
      } catch (error) {
        this.setState({
          errorMessagePassword: "There is some error. Please try again later.",
          disabled: false,
          successMessageEmail: "",
          show_resend_button: true,
          resendPinButton: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessagePassword: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    }
  };

  verifyPincode = async () => {
    //e.preventDefault();
    let { user_name, selected_email, pincode, res_url } = this.state;

    this.setState({
      verifyPasswordButton: true,
    });
    if (this.fieldValidationUsername() === true) {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "verify_pincode",
            JSON.stringify({
              username: user_name,
              email: selected_email,
              pincode: pincode,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: res_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("verifyPincode response.data : ", response.data);
            if (response.data.status === 404 || response.data.status === 422) {
              this.setState({
                errorMessagePassword:
                  "There is some error while verifying the pincode.",
                disabled: false,
                verifyPasswordButton: false,
                successMessageEmail: "",
              });
              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
            }

            if (response.data.status === 403) {
              this.setState({
                errorMessagePassword: response.data.message,
                disabled: false,
                verifyPasswordButton: false,
                successMessageEmail: "",
              });
              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessageEmail: "",
                errorMessagePassword: "",
                verifyPasswordButton: false,
              });
              this.nextStep();
            }
          });
      } catch (error) {
        this.setState({
          errorMessagePassword: "There is some error. Please try again later.",
          disabled: false,
          successMessageEmail: "",
          verifyPasswordButton: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessagePassword: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    }
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
    console.log("STEP: ", this.state.step);
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  render() {
    const { step } = this.state;
    const {
      email,
      errorMessage,
      successMessage,
      selected_email,
      verifyEmailButton,
      verifyPasswordButton,
      verifyConsentButton,
      resendPinButton,
      billing_information,
      personal_information,
      number_information,
      return_url,
      privacy_url,
      terms_url,
      reseller_comp,
      company_id,
      res_url,
      successMessageEmail,
      show_resend_button,
      resend_pin_text,
      countdown,
      password,
      confirm_password,
      errorPassword,
      errorConfirmPassword,
      verifyDetailsButton,
    } = this.state;
    const values = {
      email,
      errorMessage,
      successMessage,
      selected_email,
      verifyEmailButton,
      verifyPasswordButton,
      verifyConsentButton,
      resendPinButton,
      billing_information,
      personal_information,
      number_information,
      return_url,
      privacy_url,
      terms_url,
      reseller_comp,
      company_id,
      res_url,
      successMessageEmail,
      show_resend_button,
      resend_pin_text,
      countdown,
      password,
      confirm_password,
      errorPassword,
      errorConfirmPassword,
      verifyDetailsButton,
    };
    return (
      <div className="nk-body bg-white npc-general pg-auth">
        <div className="nk-app-root">
          <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
              <div className="nk-content ">
                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                  {this.state.pageError !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.pageError}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {this.state.tableLoaderSimple === true ? (
                        tableLoaderSimple()
                      ) : (
                        <>
                          <div className="brand-logo pb-4 text-center">
                            <Link
                              color="inherit"
                              to={`/login/${this.state.res_url}`}
                              className="logo-link"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <img
                                className="logo-light logo-img logo-img-lg"
                                src={LOGO}
                                alt="logo"
                              />
                              <img
                                className="logo-dark logo-img logo-img-lg"
                                src={LOGO}
                                alt="logo-dark"
                              />
                            </Link>
                          </div>
                          <div
                            className="card card-bordered"
                            id="login_form_main"
                          >
                            <div className="card-inner">
                              {(() => {
                                switch (step) {
                                  default:
                                    return (
                                      <h1>
                                        User Forms not working. Enable
                                        Javascript!
                                      </h1>
                                    );
                                  case 1:
                                    return (
                                      <>
                                        {this.state.errorMessageEmail !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {this.state.errorMessageEmail}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <VerifyEmail
                                          nextStep={this.nextStep}
                                          handleChange={this.handleChange}
                                          checkUsername={this.checkUsername}
                                          values={values}
                                        />
                                      </>
                                    );
                                  case 2:
                                    return (
                                      <>
                                        {this.state.errorMessagePassword !==
                                        "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessagePassword
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessageEmail !==
                                        "" ? (
                                          <div
                                            className="example-alert mb-3"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .successMessageEmail
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <GetPincode
                                          nextStep={this.nextStep}
                                          prevStep={this.prevStep}
                                          handleChangePin={this.handleChangePin}
                                          handleChangePinComplete={
                                            this.handleChangePinComplete
                                          }
                                          verifyPincode={this.verifyPincode}
                                          resendPincode={this.resendPincode}
                                          addMenuHistory={this.addMenuHistory}
                                          values={values}
                                        />
                                      </>
                                    );
                                  case 3:
                                    return (
                                      <>
                                        {this.state.errorMessageConsent !==
                                        "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageConsent
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <GetPassword
                                          nextStep={this.nextStep}
                                          prevStep={this.prevStep}
                                          handleChange={this.handleChange}
                                          handleChanges={this.handleChanges}
                                          formSubmit={this.formSubmit}
                                          values={values}
                                        />
                                      </>
                                    );
                                  case 4:
                                    return (
                                      <SsoSuccess
                                        firstStep={this.firstStep}
                                        values={values}
                                      />
                                    );
                                }
                              })()}

                              <div className="form-note-s2 text-center pt-4">
                                {" "}
                                {this.state.step < 4 ? (
                                  <Link
                                    color="inherit"
                                    to={`/login/${this.state.res_url}`}
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    Return to login
                                  </Link>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="nk-footer nk-auth-footer-full">
                  <div className="container wide-lg">
                    <div className="row g-3 text-center">
                      <div className="col-lg-12">
                        <div className="nk-block-content text-center ">
                          <p className="text-soft">
                            &copy; {this.state.currentYear} {COMPANY_NAME}. All
                            Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="nk-app-root">
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn btn-white btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  {auth.getResellerLogo() !== "" ? (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                      />
                    </a>
                  ) : (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={LOGO}
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={LOGO}
                      />
                    </a>
                  )}
                </div>
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h5 className="nk-block-title">Reset password</h5>
                    {this.state.errorMessage !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>Error</h4>
                            <p>{this.state.errorMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.successMessage !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-success">
                          <div className="alert-text">
                            <h4>Success</h4>
                            <p>{this.state.successMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <br />
                    <div className="nk-block-des">
                      <p>
                        If you forgot your password, well, then we’ll email you
                        instructions to reset your password.
                      </p>
                    </div>
                  </div>
                </div>
                <form
                  method="post"
                  className="forgotForm"
                  onSubmit={this.formPost}
                >
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Email
                      </label>
                      
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-lg btn-block"
                      style={{
                        backgroundColor: THEME_COLOR,
                        color: THEME_TEXT_COLOR,
                      }}
                      type="submit"
                      disabled={this.state.btnDisable}
                    >
                      Send Reset Link
                    </button>
                  </div>
                </form>
                <div className="form-note-s2 pt-5">
                  {auth.getUrlType() === "Simple" ? (
                    <a href={`/${auth.getResellerIdentifier()}`}>
                      <strong>Back to login</strong>
                    </a>
                  ) : (
                    <a href="/">
                      <strong>Sign in instead</strong>
                    </a>
                  )}
                </div>
              </div>
              <div className="nk-block nk-auth-footer">
                <div className="mt-3">
                  <p>
                    &copy; 2022-2023{" "}
                    <a
                      href="https://onboardsoft.com/"
                      style={{ color: "#526484" }}
                    >
                      OnBoardsoft LLC
                    </a>
                    . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img className="round" src={PROMO_A} alt="" />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>
                      {auth.getResellerRegTitle()} Agents / Channel Partners
                    </h4>
                    <p>
                      If you forgot your password, well, then we’ll email you
                      instructions to reset your password.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
      </div>
    );
  }
}
