import React, { Component, Fragment } from "react";
import axios from "axios";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { HTML5_FMT } from "moment";
import { ValidateEmail, normalize, ValidateDomain } from "../../config/utility";

export class VerifyEmail extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      dnsList: [],
      verifyEmailButton: false,
    };
  }

  SubmitFirst = async () => {
    const { values, checkUsername } = this.props;
    console.log("this.state.email: ", values.selected_email);
    let firstResult = await checkUsername();
  };

  render() {
    const {
      values,
      handleChange,
      checkUsername,
      verifyEmailButton,
      addMenuHistory,
    } = this.props;

    return (
      <>
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Sign-In</h4>
            <div className="nk-block-des">Use your Voyce Telecom account</div>
          </div>
        </div>
        <form method="post">
          <div className="form-group">
            <TextField
              required
              id="email"
              name="email"
              type="text"
              label="Email or Username"
              value={values.email}
              inputProps={{ maxLength: 100 }}
              onChange={handleChange("email")}
              helperText="Enter your email or username"
              variant="outlined"
              autoFocus
              fullWidth
            />
          </div>

          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="password">
                &nbsp;
              </label>
              <Link
                color="inherit"
                to={`/forgot/${values.res_url}`}
                className=""
                style={{
                  cursor: "pointer",
                }}
              >
                Forgot password?
              </Link>
            </div>
          </div>

          <hr />
          <div className="form-group text-right">
            {values.verifyEmailButton ? (
              <button
                className="btn btn-lg btn-primary btn-block"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Processing...</span>
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-lg btn-primary btn-block"
                disabled={this.state.disabled}
                onClick={this.SubmitFirst}
              >
                Next
              </button>
            )}
          </div>
        </form>
      </>
    );
  }
}

export default VerifyEmail;
