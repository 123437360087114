import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import LOGO from "../../../assets/images/logo.png";

import VerifyEmail from "../../../components/SsoLogin/VerifyEmail";
import GetPassword from "../../../components/SsoLogin/GetPassword";
import GetConsent from "../../../components/SsoLogin/GetConsent";
import SsoSuccess from "../../../components/SsoLogin/Success";
import TrunkForm from "../../../components/SsoLogin/TrunkForm";

import { COMPANY_NAME, THEME_COLOR } from "../../../config/config";

import { ValidateEmail, axiosConfig } from "../../../config/utility";
import { tableLoaderSimple } from "../../../components/ContentLoaders/table_loader_simple";
import helpherClass from "../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
const auth = new helpherClass();

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      errorEmail: "",
      errorPass: "",
      email: "",
      password: "",
      username: "",
      errorMessage: "",
      errorMessageEmail: "",
      errorMessagePassword: "",
      errorMessageConsent: "",
      errorMessageLoggedIn: "",
      disabled: false,
      loader: "",
      successMessage: "",
      resellerData: [],
      tableLoader: true,
      tableLoaderSimple: true,
      res_accountno: "",
      res_url: "",
      res_hash: "",
      res_title: "",
      pageError: "",
      url_type: "Simple",
      step: 1,
      email: "",
      password: "",
      selected_email: "",
      verifyEmailButton: false,
      verifyPasswordButton: false,
      verifyConsentButton: false,
      billing_information: true,
      personal_information: true,
      number_information: true,
      return_url: "",
      privacy_url: "",
      terms_url: "",
      reseller_comp: "",
      company_id: "",
      have_loggedin_account: false,
      loggedin_accounts_list: [],
      direct_hash: "",
      loading_row: [],
      disableOtherRows: false,
      currentYear: new Date().getFullYear(),
      TrunksList: [],
      trunkName: "",
      trunkIp: "",
      trunk_cps: "2",
      trunk_session: "10",
      trunk_number_Format: "10DIGIT",
      trunk_port: "5060",
      errorMessageTrunkForm: "",
      successMessageTrunkForm: "",
      create_trunk_button: false,
      waiting: false,
      go_to_dashboard_button: false,
      userHash: "",
    };
  }

  async componentDidMount() {
    console.log("Parent Window", window.opener);
    let reseller_url = this.props.match.params.reseller_url;
    this.setState({
      tableLoaderSimple: true,
    });

    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "check_reseller_login",
          JSON.stringify({
            reseller_url: reseller_url,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("response.data start : ", response.data);
          if (
            response.data.status === 404 ||
            response.data.status === 422 ||
            response.data.errors !== ""
          ) {
            this.setState({
              errorMessage: "",
              successMessage: "",
              show_form: false,
              pageError:
                "Sorry we are unable to process your request. Please contact admin for more details.",
              tableLoaderSimple: false,
            });
          }

          if (
            response.data.status === 404 &&
            response.data.message === "no_reseller"
          ) {
            this.setState({
              errorMessage: "",
              pageError:
                "Reseller does not exists. Please contact support or try again with correct URL.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
            });
          } else if (response.data.status === 403) {
            this.setState({
              errorMessage: "There is some error.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
              pageError: "",
            });
          } else if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            Cookies.set(
              "resellerhash__",
              CryptoJS.AES.encrypt(
                reseller_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resellerreturnurl__",
              CryptoJS.AES.encrypt(
                response.data.return_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resellerprivacyurl__",
              CryptoJS.AES.encrypt(
                response.data.privacy_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "reslogo__",
              CryptoJS.AES.encrypt(
                response.data.reseller_logo,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resellertermsurl__",
              CryptoJS.AES.encrypt(
                response.data.terms_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resellercompanyname__",
              CryptoJS.AES.encrypt(
                response.data.reseller_comp,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            let existingArray =
              JSON.parse(localStorage.getItem("loggedin_accounts")) || [];
            let have_loggedin_account = false;
            let loggedin_accounts_list = [];

            //Check Expiry Timestamp
            let newData = [];
            if (existingArray && existingArray.length > 0) {
              let currentDate = new Date();

              //let fifteenDaysInMilliseconds = 15 * 24 * 60 * 60 * 1000;
              let fifteenDaysInMilliseconds = 1 * 60 * 1000;
              newData = existingArray.filter((item) => {
                let item_timestamp = new Date(item.expiry);
                console.log("currentDate: ", currentDate);
                console.log("item_timestamp: ", item_timestamp);
                let timeDifferenceInMilliseconds = currentDate - item_timestamp;

                if (timeDifferenceInMilliseconds > fifteenDaysInMilliseconds) {
                  console.log("Fifteen days have passed.");
                } else {
                  console.log("Fifteen days have not passed yet.");
                  return item;
                }
              });
            }
            //console.log("newData: ", newData);
            // Check if fifteen days have passed

            if (newData && newData.length > 0) {
              have_loggedin_account = true;
              loggedin_accounts_list = newData;
            }
            localStorage.removeItem("loggedin_accounts");
            localStorage.setItem("loggedin_accounts", JSON.stringify(newData));

            //console.log("loggedin_accounts_list: ", loggedin_accounts_list);
            console.log("local storage here ");
            this.setState({
              errorMessage: "",
              have_loggedin_account: have_loggedin_account,
              loggedin_accounts_list: loggedin_accounts_list,
              return_url: response.data.return_url,
              privacy_url: response.data.privacy_url,
              terms_url: response.data.terms_url,
              tableLoader: false,
              show_form: true,
              pageError: "",
              res_url: reseller_url,
              res_hash: response.data.reseller_hash,
              tableLoaderSimple: true,
              reseller_comp: response.data.reseller_comp,
            });
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        tableLoaderSimple: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const userHash = urlParams.get("user_hash");
    setTimeout(() => {
      console.log("praam here ");
      if (userHash) {
        const new_hash = atob(userHash);
        const UserData = JSON.parse(new_hash);
        const userEmail = UserData.email;

        var existingArray =
          JSON.parse(localStorage.getItem("loggedin_accounts")) || [];
        //Check if Value already exists
        var valueExists =
          $.grep(existingArray, function (item) {
            return item.email === userEmail;
          }).length > 0;

        if (!valueExists) {
          this.setState({
            userHash: userHash,
            email: UserData.email,
          });
          setTimeout(() => {
            this.checkUsernameForHash(reseller_url);
          }, 400);
        } else {
          this.setState({
            show_form: true,
            tableLoaderSimple: false,
          });
        }
      } else {
        this.setState({
          show_form: true,
          tableLoaderSimple: false,
        });
      }
    }, 2000);
  }

  get_email_values = async () => {
    axios
      .get(process.env.REACT_APP_API_URL + "get_loggedin_accounts", {
        withCredentials: true,
      })
      .then((response) => {
        //console.log("Data received:", response.data);
        // The 'myCookie' will be included in the response cookies
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChanges = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  checkUsernameAndKey = async () => {
    let { email, direct_hash, res_url } = this.state;
    this.setState({
      verifyEmailButton: true,
    });
    if (email !== "") {
      try {
        await axios
          .post(
            process.env.REACT_APP_API_URL + "check_username_and_key",
            JSON.stringify({
              email: email,
              direct_hash: direct_hash,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: res_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("LOGIN DETAILS: ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "Invalid token"
            ) {
              this.setState({
                errorMessageLoggedIn: "You token is invalid or expired.",
                disabled: false,
                verifyEmailButton: false,
              });
              setTimeout(() => {
                this.setState({
                  errorMessageLoggedIn: "",
                });
              }, 4000);
              //return false;
            } else if (
              response.data.status === 404 &&
              response.data.message === "User not found."
            ) {
              this.setState({
                errorMessageLoggedIn: "User not found.",
                disabled: false,
                verifyEmailButton: false,
              });
              setTimeout(() => {
                this.setState({
                  errorMessageLoggedIn: "",
                });
              }, 4000);
              //return false;
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              if (response.data.consent_added) {
                this.setState({
                  successMessage: "",
                  have_loggedin_account: false,
                  selected_email: response.data.user_email,
                  errorMessageLoggedIn: "",
                  company_id: response.data.company_id,
                });
                this.setState({
                  waiting: true,
                });
                this.submitConsentInner();
                setTimeout(() => {
                  this.setState({
                    waiting: false,
                  });
                  if (this.state.TrunksList.length > 0) {
                    console.log("trunk added");
                    this.nextStepThree();
                  } else {
                    console.log("trunk not added");
                    this.nextStepFour();
                  }
                }, 1200);
                //this.nextStepThree();
              } else {
                this.setState({
                  successMessage: "",
                  have_loggedin_account: false,
                  selected_email: response.data.user_email,
                  errorMessageLoggedIn: "",
                  verifyPasswordButton: false,
                  company_id: response.data.company_id,
                });
                this.nextStepTwo();
              }
              //return true;
            }
          });
      } catch (error) {
        //return false;
        this.setState({
          errorMessageLoggedIn:
            "There is some error while checking the username.",
          verifyEmailButton: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessageLoggedIn: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessageLoggedIn: "Please enter the username or email.",
        verifyEmailButton: false,
      });
      setTimeout(() => {
        this.setState({
          errorMessageLoggedIn: "",
        });
      }, 4000);
    }
    //return false;
  };

  // checkNewRegister = async (reseller_user_comp) => {
  //   var existingArray =
  //     JSON.parse(localStorage.getItem("loggedin_accounts")) || [];

  //   var user = existingArray.find(
  //     (item) => item.company_id == reseller_user_comp
  //   );
  //   this.setState({
  //     company_id: user['company_id'],
  //     direct_hash: user['hash'],
  //   });
  //   // if (user) {
  //   //   console.log(" value ", user);
  //   // } else {
  //   //   console.log("value not ", user);
  //   // }
  // };

  checkUsername = async () => {
    let { email, selected_email, res_url } = this.state;
    this.setState({
      verifyEmailButton: true,
    });
    if (email !== "") {
      try {
        await axios
          .post(
            process.env.REACT_APP_API_URL + "check_username",
            JSON.stringify({
              email: email,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: res_url,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("checkUsername DETAILS: ", response.data);
            if (response.data.status === 404 || response.data.status === 422) {
              this.setState({
                errorMessageEmail:
                  "There is some error while processing your request.",
                disabled: false,
                verifyEmailButton: false,
              });
              setTimeout(() => {
                this.setState({
                  errorMessageEmail: "",
                });
              }, 4000);
              //return false;
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage: "",
                selected_email: response.data.user_email,
                username: response.data.user_name,
                company_id: response.data.company_id,
                errorMessageEmail: "",
                verifyEmailButton: false,
              });
              this.nextStep();
              //return true;
            }
          });
      } catch (error) {
        //return false;
        this.setState({
          errorMessageEmail: "There is some error while checking the username.",
          verifyEmailButton: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessageEmail: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessageEmail: "Please enter the username or email.",
        verifyEmailButton: false,
      });
      setTimeout(() => {
        this.setState({
          errorMessageEmail: "",
        });
      }, 4000);
    }
    //return false;
  };

  checkUsernameForHash = async (res_url) => {
    let { email, selected_email } = this.state;
    try {
      console.log("check hash API ");
      await axios
        .post(
          process.env.REACT_APP_API_URL + "check_username",
          JSON.stringify({
            email: email,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            reseller_url: res_url,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("checkUsername DETAILS: ", response.data);
          if (response.data.status === 404 || response.data.status === 422) {
            var res_url = this.state.res_url;
            var user_hash = this.state.userHash;
            window.location.href =
              "/register/" + res_url + "?user_hash=" + user_hash;
          }
          if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            this.setState({
              successMessage: "",
              selected_email: response.data.user_email,
              username: response.data.user_name,
              company_id: response.data.company_id,
              errorMessageEmail: "",
              verifyEmailButton: false,
              show_form: true,
              tableLoaderSimple: false,
            });
            //this.nextStep();
            //return true;
          }
        });
    } catch (error) {
      //return false;
      this.setState({
        errorMessageEmail: "There is some error while checking the username.",
        verifyEmailButton: false,
      });
      setTimeout(() => {
        this.setState({
          errorMessageEmail: "",
        });
      }, 4000);
      console.log("catch : ", error);
    }
  };

  checkPassword = async () => {
    let { email, password, res_url } = this.state;

    this.setState({
      verifyPasswordButton: true,
    });
    let is_validated = true;
    if (password === "") {
      is_validated = false;
    }
    if (is_validated) {
      try {
        await axios
          .post(
            process.env.REACT_APP_API_URL + "check_password",
            JSON.stringify({
              email: email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: res_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("Password DETAILS: ", response.data);
            if (response.data.status === 404 || response.data.status === 422) {
              this.setState({
                errorMessagePassword:
                  "There is some error while processing your request. Please try again later.",
                disabled: false,
                verifyPasswordButton: false,
              });
              setTimeout(() => {
                this.setState({
                  errorMessagePassword: "",
                });
              }, 4000);
              //return false;
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              //Set Logged In accounts to locl storage
              var existingArray =
                JSON.parse(localStorage.getItem("loggedin_accounts")) || [];
              //Check if Value already exists
              var valueExists =
                $.grep(existingArray, function (item) {
                  return item.email === response.data.user_email;
                }).length > 0;
              if (!valueExists) {
                existingArray.push({
                  email: response.data.user_email,
                  hash: response.data.hash,
                  expiry: response.data.expiry,
                });
              } else {
                //Remove existing value and push new values in array
                existingArray = existingArray.filter(
                  (item) => item.email !== response.data.user_email
                );
                existingArray.push({
                  email: response.data.user_email,
                  hash: response.data.hash,
                  expiry: response.data.expiry,
                });
              }
              localStorage.setItem(
                "loggedin_accounts",
                JSON.stringify(existingArray)
              );

              if (response.data.consent_added) {
                this.setState({
                  successMessage: "",
                  selected_email: response.data.user_email,
                  errorMessagePassword: "",
                });
                this.submitConsentInner();
                setTimeout(() => {
                  if (this.state.TrunksList.length > 0) {
                    console.log("trunk added");
                    this.nextStepTwo();
                  } else {
                    console.log("trunk not added");
                    this.nextStepThree();
                  }
                }, 2000);
              } else {
                this.setState({
                  successMessage: "",
                  selected_email: response.data.user_email,
                  errorMessagePassword: "",
                  verifyPasswordButton: false,
                });
                this.nextStep();
              }
              //this.nextStep();
              //return true;
            }
          });
      } catch (error) {
        //return false;
        this.setState({
          errorMessagePassword:
            "There is some error while checking the username.",
          verifyPasswordButton: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessagePassword: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessagePassword: "Please enter the password.",
        verifyPasswordButton: false,
      });
      setTimeout(() => {
        this.setState({
          errorMessagePassword: "",
        });
      }, 4000);
    }
    //return false;
  };

  submitConsentInner = async () => {
    let { email, return_url, res_url, company_id, username } = this.state;
    this.setState({
      verifyPasswordButton: true,
    });
    if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessagePassword: "Email is required.",
        verifyPasswordButton: false,
      });
    } else {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessagePassword: "",
        disabled: true,
        loader: "show",
      });

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "submit_consent",
            JSON.stringify({
              email: email,
              return_url: return_url,
              reseller_hash: res_url,
              company_id: company_id,
              username: username,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: res_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("return_url: ", return_url);
            //console.log("CONSENT DETAILS: ", response.data);
            console.log(
              "CONSENT  INNER DETAILS Data: ",
              response.data.account_details
            );
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessagePassword:
                  "There is some error. Please try again later",
                disabled: false,
                verifyPasswordButton: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Please wait, redirecting you to the dashboard.",
                errorMessagePassword: "",
                verifyPasswordButton: false,
                TrunksList: response.data.account_details.trunkInformation,
              });

              // setTimeout(function () {
              //   window.close();
              //   //window.location.replace("/login/" + res_url);
              // }, 5000);
            }
          });
      } catch (error) {
        this.setState({
          successMessage: "",
          errorMessagePassword: "There is some error. please try again later.",
          verifyPasswordButton: false,
        });
        console.log("catch : ", error);
      }
    }
  };

  submitConsent = async () => {
    let { email, return_url, res_url, company_id, username } = this.state;
    this.setState({
      verifyConsentButton: true,
    });
    if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessageConsent: "Email is required.",
        verifyConsentButton: false,
      });
    } else {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessageConsent: "",
        disabled: true,
        loader: "show",
      });

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "submit_consent",
            JSON.stringify({
              email: email,
              return_url: return_url,
              reseller_hash: res_url,
              company_id: company_id,
              username: username,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: res_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("return_url: ", return_url);
            //console.log("CONSENT DETAILS: ", response.data);
            console.log("CONSENT Data: ", response.data.account_details);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessageConsent:
                  "There is some error. Please try again later",
                disabled: false,
                verifyConsentButton: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Please wait, redirecting you to the dashboard.",
                errorMessageConsent: "",
                verifyConsentButton: false,
                TrunksList: response.data.account_details.trunkInformation,
              });
              setTimeout(() => {
                if (this.state.TrunksList.length > 0) {
                  console.log("trunk added");
                  this.nextStep();
                } else {
                  console.log("trunk not added");
                  this.nextStepTwo();
                }
              }, 1000);
              //this.nextStep();
              // setTimeout(function () {
              //   window.close();
              // }, 5000);
            }
          });
      } catch (error) {
        this.setState({
          successMessage: "",
          errorMessageConsent: "There is some error. please try again later.",
          verifyConsentButton: false,
        });
        console.log("catch : ", error);
      }
    }
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
    console.log("STEP: ", this.state.step);
  };

  nextStepTwo = () => {
    const { step } = this.state;

    this.setState({
      step: step + 2,
    });
    console.log("STEP: ", this.state.step);
  };

  nextStepThree = () => {
    const { step } = this.state;

    this.setState({
      step: step + 3,
    });
  };

  nextStepFour = () => {
    const { step } = this.state;

    this.setState({
      step: step + 4,
    });
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  SSOStep = () => {
    this.setState({
      step: 4,
    });
  };

  addMenuHistory = (link) => {
    this.props.history.push(link);
  };

  UseAnother = () => {
    this.setState({
      have_loggedin_account: false,
    });
  };

  removeAccount = (email) => {
    const existingArray =
      JSON.parse(localStorage.getItem("loggedin_accounts")) || [];
    const updatedArray = existingArray.filter((item) => item.email !== email);
    let have_loggedin_account = false;
    let loggedin_accounts_list = [];
    if (updatedArray.length > 0) {
      have_loggedin_account = true;
      loggedin_accounts_list = updatedArray;
    }
    localStorage.setItem("loggedin_accounts", JSON.stringify(updatedArray));
    this.setState({
      have_loggedin_account: have_loggedin_account,
      loggedin_accounts_list: loggedin_accounts_list,
    });
  };

  removeAllAccounts = () => {
    // Clear the localStorage
    localStorage.removeItem("loggedin_accounts");

    // Clear the state
    this.setState({
      have_loggedin_account: false,
      loggedin_accounts_list: [],
    });
  };

  useAccount = async (email, hash, index) => {
    this.handleRowClick(index);
    this.setState({
      email: email,
      direct_hash: hash,
    });
    setTimeout(() => {
      this.checkUsernameAndKey();
    }, 1000);
  };

  fetchDataFromAPI = (rowId) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(); // Resolves the promise after a delay to simulate data fetch
      }, 2000); // Simulate 2 seconds delay
    });
  };

  handleRowClick = (rowId) => {
    if (!this.state.disableOtherRows) {
      this.setState({
        loading_row: {
          [rowId]: true, // Set the loading status to true for the clicked row
        },
        disableOtherRows: true, // Disable other rows
      });

      this.fetchDataFromAPI(rowId)
        .then(() => {
          // After the data is fetched, set the loading status to false and enable other rows
          this.setState({
            loading_row: {
              [rowId]: false, // Set the loading status to false for the clicked row
            },
            disableOtherRows: false, // Enable other rows
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle error if needed and set loading status to false and enable other rows
          this.setState({
            loading_row: {
              [rowId]: false, // Set the loading status to false for the clicked row
            },
            disableOtherRows: false, // Enable other rows
          });
        });
    }
  };

  submitTrunk = async () => {
    this.setState({
      errorMessageTrunkForm: "",
      successMessageTrunkForm: "",
    });
    let {
      trunkName,
      trunkIp,
      trunk_cps,
      trunk_session,
      trunk_number_Format,
      trunk_port,
      res_url,
      company_id,
    } = this.state;
    var is_validated = true;
    if (trunkName == "") {
      this.setState({
        errorMessageTrunkForm: "Please enter the Trunk Name.",
      });
      is_validated = false;
    } else if (trunkIp == "") {
      this.setState({
        errorMessageTrunkForm: "Please enter the IP.",
      });
    } else if (trunk_cps == "") {
      this.setState({
        errorMessageTrunkForm: "Please enter the CPS.",
      });
    } else if (trunk_session == "") {
      this.setState({
        errorMessageTrunkForm: "Please enter the Sessions.",
      });
    } else if (trunk_port == "") {
      this.setState({
        errorMessageTrunkForm: "Please enter the Port.",
      });
    }

    if (is_validated) {
      this.setState({
        create_trunk_button: true,
      });
      try {
        await axios
          .post(
            process.env.REACT_APP_API_URL + "create_trunk",
            JSON.stringify({
              company_id: company_id,
              trunkName: trunkName,
              trunkIp: trunkIp,
              trunk_cps: trunk_cps,
              trunk_session: trunk_session,
              trunk_number_Format: trunk_number_Format,
              trunk_port: trunk_port,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: res_url,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log(response);
            if (response.data.status === 404 || response.data.status === 422) {
              this.setState({
                errorMessageTrunkForm:
                  "There is some error while processing your request. Please try again later.",
                disabled: false,
                create_trunk_button: false,
              });
              setTimeout(() => {
                this.setState({
                  errorMessageTrunkForm: "",
                });
              }, 4000);
              //return false;
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessageTrunkForm:
                  "Origination Trunk Created Successfully",
                disabled: false,
                create_trunk_button: false,
              });
              setTimeout(() => {
                this.setState({
                  errorMessageTrunkForm: "",
                  successMessageTrunkForm: "",
                });
                this.SSOStep();
              }, 3000);
            }
          });
      } catch (error) {
        this.setState({
          errorMessageTrunkForm:
            "There is some error while creating the Trunk.",
          create_trunk_button: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessageTrunkForm: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    } else {
      setTimeout(() => {
        this.setState({
          errorMessageTrunkForm: "",
          create_trunk_button: false,
        });
      }, 3000);
    }
  };

  goToVoyce = async () => {
    console.log("go2dashboard", this.state);

    let { res_url, company_id, email } = this.state;

    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "get_sso",
          JSON.stringify({
            email: email,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            reseller_url: res_url,
            company_id: company_id,
            email: email,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("checkSSo DETAILS: ", response.data);
          if (response.data.status === 404 || response.data.status === 422) {
            this.setState({
              errorMessageEmail:
                "There is some error while processing your request.",
              disabled: false,
              verifyEmailButton: false,
            });
            setTimeout(() => {
              this.setState({
                errorMessageEmail: "",
              });
            }, 4000);
            //return false;
          }

          if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            let token = response.data.token;
            let api_key = response.data.api_key;
            let api_auth = response.data.api_auth;
            let redirect_url = response.data.redirect_url;

            let object = {
              api_key: api_key,
              api_auth: api_auth,
              token: token,
            };
            const jsonString = JSON.stringify(object);
            const user_hash = btoa(jsonString);
            const user = token;
            const url =
              redirect_url + "portal/sso.php" + "?user_hash=" + user_hash;

            // console.log(
            //   redirect_url + "portal/sso.php" + "?user_hash=" + user_hash
            // );

            // if (window.opener == null) {
            //   window.open(url, "_blank");
            // } else if (window.opener !== null) {
            //   window.opener.location.href = url;
            // }

            //window.opener.location.href = url;

            window.open(url, "_blank");

            /* var set_message = {
              portal: 'voycetel',
              redirect_url : url,
            };
            window.opener.postMessage(set_message, '*');
            */
            setTimeout(() => {
              window.close();
            }, 1000);

            // this.setState({
            //   successMessage: "",
            //   selected_email: response.data.user_email,
            //   username: response.data.user_name,
            //   company_id: response.data.company_id,
            //   errorMessageEmail: "",
            //   verifyEmailButton: false,
            // });
            //this.nextStep();
            //return true;
          }
        });
    } catch (error) {
      //return false;
      this.setState({
        errorMessageEmail: "There is some error while redirecting to url.",
        verifyEmailButton: false,
      });
      setTimeout(() => {
        this.setState({
          errorMessageEmail: "",
        });
      }, 4000);
      console.log("catch : ", error);
    }
  };

  render() {
    if (auth.isLogin() === true) {
      return <Redirect to="/dashboard" />;
    }
    if (auth.isAdminLogin() === true) {
      return <Redirect to="/admin_dashboard" />;
    }
    const { step } = this.state;
    const {
      email,
      errorMessage,
      successMessage,
      selected_email,
      verifyEmailButton,
      verifyPasswordButton,
      verifyConsentButton,
      billing_information,
      personal_information,
      number_information,
      password,
      return_url,
      privacy_url,
      terms_url,
      reseller_comp,
      company_id,
      res_url,
      trunkName,
      trunkIp,
      trunk_cps,
      trunk_session,
      trunk_number_Format,
      trunk_port,
      create_trunk_button,
      go_to_dashboard_button,
    } = this.state;
    const values = {
      email,
      errorMessage,
      successMessage,
      selected_email,
      verifyEmailButton,
      verifyPasswordButton,
      verifyConsentButton,
      billing_information,
      personal_information,
      number_information,
      password,
      return_url,
      privacy_url,
      terms_url,
      reseller_comp,
      company_id,
      res_url,
      trunkName,
      trunkIp,
      trunk_cps,
      trunk_session,
      trunk_number_Format,
      trunk_port,
      create_trunk_button,
      go_to_dashboard_button,
    };

    return (
      <div className="nk-body bg-white npc-general pg-auth">
        <div className="nk-app-root">
          <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
              <div className="nk-content ">
                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                  {this.state.pageError !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.pageError}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {this.state.tableLoaderSimple === true ? (
                        tableLoaderSimple()
                      ) : (
                        <>
                          <div className="brand-logo pb-4 text-center">
                            <Link
                              color="inherit"
                              to={`/login/${this.state.res_url}`}
                              className="logo-link"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <img
                                className="logo-light logo-img logo-img-lg"
                                src={LOGO}
                                alt="logo"
                              />
                              <img
                                className="logo-dark logo-img logo-img-lg"
                                src={LOGO}
                                alt="logo-dark"
                              />
                            </Link>
                          </div>
                          {this.state.have_loggedin_account ? (
                            <div
                              className="card card-bordered card-full"
                              id="loggedInAccounts"
                            >
                              <div className="card-inner-group">
                                <div className="card-inner  d-flex justify-content-center">
                                  <div className="card-title-group">
                                    <div className="card-title text-center">
                                      <h4 className="title text-center">
                                        Choose an Account
                                      </h4>
                                      <h6 className="title text-center">
                                        to continue to{" "}
                                        {this.state.reseller_comp}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                {this.state.errorMessageLoggedIn !== "" ? (
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div
                                        className="example-alert"
                                        style={{
                                          marginBottom: "15px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="alert alert-pro alert-danger">
                                          <div className="alert-text">
                                            <h4>Error</h4>
                                            <p>
                                              {this.state.errorMessageLoggedIn}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {this.state.loggedin_accounts_list &&
                                this.state.loggedin_accounts_list.length > 0 ? (
                                  <>
                                    {this.state.loggedin_accounts_list.map(
                                      (accounts, index) => (
                                        <div
                                          className="card-inner card-inner-md card-hover"
                                          key={index}
                                        >
                                          <div className="user-card">
                                            <div className="user-avatar bg-primary-dim">
                                              {/* <span
                                                style={{
                                                  textTransform: "Uppercase",
                                                }}
                                              >
                                                {accounts.email.charAt(0)}
                             
                                              </span> */}
                                              <span>
                                                <em className="icon ni ni-user-circle"></em>
                                              </span>
                                            </div>
                                            <div className="user-info">
                                              <span
                                                className="lead-text"
                                                style={{ fontWeight: "normal" }}
                                              >
                                                <a
                                                  onClick={() =>
                                                    this.useAccount(
                                                      accounts.email,
                                                      accounts.hash,
                                                      index
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  className={
                                                    this.state
                                                      .disableOtherRows &&
                                                    !this.state.loading_row[
                                                      index
                                                    ]
                                                      ? "btn disabled"
                                                      : "btn"
                                                  }
                                                >
                                                  {accounts.email}{" "}
                                                  {this.state.loading_row[
                                                    index
                                                  ] && (
                                                    <div
                                                      className="spinner-grow spinner-grow-sm"
                                                      role="status"
                                                    >
                                                      <span className="visually-hidden">
                                                        Loading...
                                                      </span>
                                                    </div>
                                                  )}
                                                </a>
                                              </span>
                                            </div>
                                            <div className="user-action">
                                              <div className="drodown">
                                                <a
                                                  onClick={() =>
                                                    this.removeAccount(
                                                      accounts.email
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  className="btn btn-icon btn-trigger me-n1"
                                                  title="Remove"
                                                >
                                                  <em className="icon ni ni-user-remove"></em>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                    <div className="card-inner card-inner-md">
                                      <div className="user-card">
                                        <div className="user-avatar bg-primary-dim">
                                          <span>
                                            <em className="icon ni ni-user-circle"></em>
                                          </span>
                                        </div>
                                        <div className="user-info">
                                          <span className="lead-text">
                                            <a
                                              onClick={this.UseAnother}
                                              style={{
                                                cursor: "pointer",
                                                fontWeight: "normal",
                                                paddingLeft: "18px",
                                              }}
                                            >
                                              Use another account
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-inner card-inner-md">
                                      <div className="user-card">
                                        <div className="user-avatar bg-primary-dim">
                                          <span>
                                            <em class="icon ni ni-signout"></em>
                                          </span>
                                        </div>
                                        <div className="user-info">
                                          <span className="lead-text">
                                            <a
                                              onClick={this.removeAllAccounts}
                                              style={{
                                                cursor: "pointer",
                                                fontWeight: "normal",
                                                paddingLeft: "18px",
                                              }}
                                            >
                                              Sign out of all accounts
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <div
                              className="card card-bordered"
                              id="login_form_main"
                            >
                              <div className="card-inner">
                                {(() => {
                                  console.log("Main step is => ", step);
                                  switch (step) {
                                    default:
                                      return (
                                        <h1>
                                          User Forms not working. Enable
                                          Javascript!
                                        </h1>
                                      );
                                    case 1:
                                      return (
                                        <>
                                          {this.state.waiting == true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state.errorMessageEmail !==
                                              "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4>Error</h4>
                                                      <p>
                                                        {
                                                          this.state
                                                            .errorMessageEmail
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              <VerifyEmail
                                                nextStep={this.nextStep}
                                                handleChange={this.handleChange}
                                                checkUsername={
                                                  this.checkUsername
                                                }
                                                values={values}
                                              />
                                            </>
                                          )}
                                        </>
                                      );
                                    case 2:
                                      return (
                                        <>
                                          {this.state.errorMessagePassword !==
                                          "" ? (
                                            <div
                                              className="example-alert"
                                              style={{ marginBottom: "15px" }}
                                            >
                                              <div className="alert alert-pro alert-danger">
                                                <div className="alert-text">
                                                  <h4>Error</h4>
                                                  <p>
                                                    {
                                                      this.state
                                                        .errorMessagePassword
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          <GetPassword
                                            nextStep={this.nextStep}
                                            prevStep={this.prevStep}
                                            handleChange={this.handleChange}
                                            checkPassword={this.checkPassword}
                                            addMenuHistory={this.addMenuHistory}
                                            values={values}
                                          />
                                        </>
                                      );
                                    case 3:
                                      return (
                                        <>
                                          {this.state.errorMessageConsent !==
                                          "" ? (
                                            <div
                                              className="example-alert"
                                              style={{ marginBottom: "15px" }}
                                            >
                                              <div className="alert alert-pro alert-danger">
                                                <div className="alert-text">
                                                  <h4>Error</h4>
                                                  <p>
                                                    {
                                                      this.state
                                                        .errorMessageConsent
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          <GetConsent
                                            nextStep={this.nextStep}
                                            prevStep={this.prevStep}
                                            submitConsent={this.submitConsent}
                                            values={values}
                                          />
                                        </>
                                      );
                                    //
                                    case 4:
                                      return (
                                        <>
                                          {this.state.errorMessageEmail !==
                                          "" ? (
                                            <div
                                              className="example-alert"
                                              style={{
                                                marginBottom: "15px",
                                              }}
                                            >
                                              <div className="alert alert-pro alert-danger">
                                                <div className="alert-text">
                                                  <h4>Error</h4>
                                                  <p>
                                                    {
                                                      this.state
                                                        .errorMessageEmail
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          <SsoSuccess
                                            firstStep={this.firstStep}
                                            addMenuHistory={this.addMenuHistory}
                                            values={values}
                                            goToVoyce={this.goToVoyce}
                                          />
                                        </>
                                      );
                                    case 5:
                                      return (
                                        <>
                                          {this.state.errorMessageTrunkForm !==
                                          "" ? (
                                            <div
                                              className="example-alert"
                                              style={{ marginBottom: "15px" }}
                                            >
                                              <div className="alert alert-pro alert-danger">
                                                <div className="alert-text">
                                                  <h4>Error</h4>
                                                  <p>
                                                    {
                                                      this.state
                                                        .errorMessageTrunkForm
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          {this.state
                                            .successMessageTrunkForm !== "" ? (
                                            <div
                                              className="example-alert"
                                              style={{ marginBottom: "15px" }}
                                            >
                                              <div className="alert alert-pro alert-success">
                                                <div className="alert-text">
                                                  <h4>Success</h4>
                                                  <p>
                                                    {
                                                      this.state
                                                        .successMessageTrunkForm
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          <TrunkForm
                                            nextStep={this.nextStep}
                                            prevStep={this.prevStep}
                                            handleChange={this.handleChange}
                                            //checkPassword={this.checkPassword}
                                            addMenuHistory={this.addMenuHistory}
                                            values={values}
                                            submitTrunk={this.submitTrunk}
                                          />
                                        </>
                                      );
                                  }
                                })()}
                                {this.state.step <= 2 ? (
                                  <div className="form-note-s2 text-center pt-4">
                                    {" "}
                                    Don't have an account?{" "}
                                    <Link
                                      color="inherit"
                                      to={`/register/${this.state.res_url}`}
                                      className=""
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      Create account
                                    </Link>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="nk-footer nk-auth-footer-full">
                  <div className="container wide-lg">
                    <div className="row g-3 text-center">
                      <div className="col-lg-12">
                        <div className="nk-block-content text-center ">
                          <p className="text-soft">
                            &copy; {this.state.currentYear} {COMPANY_NAME}. All
                            Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="nk-app-root">
          
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn-white btn btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  {auth.getResellerLogo() && auth.getResellerLogo() !== "" ? (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                        alt="logo-dark"
                      />
                    </a>
                  ) : (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo-dark"
                      />
                    </a>
                  )}
                </div>

                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h5 className="nk-block-title">Sign-In</h5>

                    <div className="nk-block-des">
                      <p>Use your verified email and password.</p>

                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      
                    </div>
                  </div>
                </div>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabItem5"
                    >
                      <em className="icon ni ni-signin"></em>
                      <span>Agent Login</span>
                    </a>
                  </li>
                  
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active" id="tabItem5">
                    {this.state.tableLoaderSimple === true ? (
                      tableLoaderSimple()
                    ) : (
                      <form
                        method="post"
                        className="loginForm"
                        onSubmit={this.formSubmit}
                      >
                        <div className="form-group">
                          <div className="form-label-group">
                            <label className="form-label" htmlFor="default-01">
                              Email
                            </label>
                            
                          </div>
                          <input
                            type="email"
                            className={
                              "form-control form-control-lg " +
                              this.state.errorEmail
                            }
                            id="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            placeholder="Enter your email address"
                          />
                        </div>
                        <div className="form-group">
                          <div className="form-label-group">
                            <label className="form-label" htmlFor="password">
                              Password
                            </label>
                            <a
                              className="link link-primary link-sm"
                              tabIndex="-1"
                              href="/forgot"
                              style={{ color: "#006393" }}
                            >
                              Forgot Password?
                            </a>
                          </div>
                          <div className="form-control-wrap">
                            <a
                              tabIndex="-1"
                              href="#"
                              className="form-icon form-icon-right passcode-switch"
                              data-target="password"
                            >
                              <em className="passcode-icon icon-show icon ni ni-eye"></em>
                              <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                            </a>
                            <input
                              type="password"
                              className={
                                "form-control form-control-lg " +
                                this.state.errorPass
                              }
                              id="password"
                              name="password"
                              onChange={this.handleChange}
                              value={this.state.password}
                              placeholder="Enter your password"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-lg btn-block"
                            disabled={this.state.disabled}
                            type="submit"
                            style={{
                              backgroundColor: THEME_COLOR,
                              color: "white",
                            }}
                          >
                            Sign in
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>

                <div className="form-note-s2 pt-4">
                  {" "}
                  New on our platform?{" "}
                  {auth.getUrlType() === "Simple" ? (
                    <a
                      href={`${this.state.res_url}/${this.state.res_hash}/register`}
                    >
                      Create an account
                    </a>
                  ) : (
                    <a href={`${this.state.res_hash}/register`}>
                      Create an account
                    </a>
                  )}
                </div>
              </div>
              <div className="nk-block nk-auth-footer">
                <div className="mt-3">
                  <p>
                    &copy; 2022-2023{" "}
                    <a
                      href="https://onboardsoft.com/"
                      style={{ color: "#526484" }}
                    >
                      OnBoardsoft LLC
                    </a>
                    . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div
                  className="slider-init"
                  data-slick='{"dots":true, "arrows":false}'
                >
                  <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_A} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>
                          {auth.getResellerRegTitle()} Agents / Channel Partners
                        </h4>
                        <p>
                          Enter your details here to login in to our system.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-dots"></div>
                <div className="slider-arrows"></div>
              </div>
            </div>
          </div>
        </div>*/}
      </div>
    );
  }
}
